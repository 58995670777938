import React, { FC, useState } from 'react';
import { useLocation } from '@reach/router';
import { omit } from 'lodash';
import { LocaleLink } from '../links/LocaleLink';
import classNames from 'classnames';
import {
  Ul,
  Div,
  Li,
  Span,
  Nav,
  Button,
  makeStyledComponent,
  H5,
  Container,
} from '../Elements/index';
import { Icon } from '../Icon';
import { Page } from '../../../common/fragments/Page';
import { useLocale } from '../../../common/hooks/useLocale';
import { ContentfulBladeSubNav } from '../../../contentful/content-types/blades/blade-sub-nav';
import { ContentfulBladeSubNavMenuItem } from '../../../contentful/content-types/blades/blade-sub-nav-menu-item';
import { RelatedBlade } from '../../../contentful/blades';
import { isAbsoluteURL, isDirectLink, pathJoin } from '../../../common/util/path';
import { TextVariantMap } from '../Text/Text.interface';
import Text from '../Text/Text';
import MediaContainer from '../MediaContainer';

export function isContentfulBladeSubNav(blade: RelatedBlade): blade is ContentfulBladeSubNav {
  return blade.contentTypeId === 'bladeSubNav';
}

interface SubNavBladeProps {
  blade: ContentfulBladeSubNav;
  pagePrefix: string;
}

export const BladeSubNav: FC<SubNavBladeProps> = ({ blade, pagePrefix }) => {
  return (
    <MediaContainer>
      {size => (
        <>
          <Div
            display={['none', 'block']}
            border="bottom"
            borderColor="gray2"
            position={blade.stickyNav ? 'sticky' : 'static'}
            zIndex={4}
            style={{
              top: size.lg ? 100 : 60,
            }}
            backgroundColor="white"
          >
            <Div position="relative" width="100%">
              <Nav width="100%" display="flex" justifyContent="between">
                <Container>
                  <Div display="flex" style={{ overflowX: 'auto' }} justifyContent="between">
                    {blade.menuTitle && (
                      <H5
                        display="flex"
                        alignItems="center"
                        aria-label="Cloudflare For Teams Title"
                        {...TextVariantMap['headline5']}
                        color="black"
                      >
                        {blade.menuTitle}
                      </H5>
                    )}
                    {!!blade.menuItems?.length && (
                      <Ul
                        display="flex"
                        marginVertical={0}
                        paddingHorizontal={0}
                        className="list nowrap"
                      >
                        {blade.menuItems.map((item, index) => (
                          <ListLinkItem
                            index={index}
                            key={`ListLinkItem-${item.contentfulId}`}
                            item={item}
                            navLength={blade.menuItems.length}
                            pagePrefix={pagePrefix}
                          />
                        ))}
                      </Ul>
                    )}

                    {!!blade.rightMenuItems?.length && (
                      <Ul
                        display="flex"
                        paddingHorizontal={0}
                        marginVertical={0}
                        className="list nowrap"
                      >
                        {blade.rightMenuItems?.map((item, index) => (
                          <ListLinkItem
                            index={index}
                            key={`ListLinkItem-${item.contentfulId}`}
                            item={item}
                            navLength={blade.rightMenuItems?.length || 0}
                            pagePrefix={pagePrefix}
                            rightLink
                          />
                        ))}
                      </Ul>
                    )}
                  </Div>
                </Container>
              </Nav>
            </Div>
          </Div>
          <MobileNav blade={blade} pagePrefix={pagePrefix} />
        </>
      )}
    </MediaContainer>
  );
};

interface ListLinkItemProps {
  active?: boolean;
  className?: string;
  item: ContentfulBladeSubNavMenuItem;
  pagePrefix: string;
  index: number;
  rightLink?: boolean;
  navLength: number;
}

const ListLinkItem: FC<ListLinkItemProps> = ({
  item,
  active,
  pagePrefix,
  index,
  navLength,
  rightLink = false,
}) => {
  const locale = useLocale() || 'en-US';
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [subMenuPosition, setSubMenuePosition] = React.useState<number>(0);
  const location = useLocation();
  const href = getHrefFromMenuItem(item);
  const activeBasedLocation = isActiveBasedOnLocation(item, location.pathname, pagePrefix, locale);
  const activeHash = location.hash === item.url;
  const isActive = active || activeBasedLocation || activeHash;
  return typeof href !== 'string' ? (
    <Li
      className={classNames('pointer', {
        active: isActive,
      })}
      display="flex"
      {...(rightLink
        ? { marginLeft: index === 0 ? 0 : 5 }
        : { marginRight: navLength - 1 === index ? 0 : 5 })}
      alignItems="center"
      color={isActive ? 'blue1' : 'black'}
      {...TextVariantMap['body2-bold']}
      onMouseEnter={e => {
        setOpen(true);
        setHovered(true);
        setSubMenuePosition(
          (e.target as any).getBoundingClientRect().left +
            window.scrollX -
            document.body.getBoundingClientRect().left
        );
      }}
      onMouseLeave={() => {
        setOpen(false);
        setHovered(false);
        setSubMenuePosition(0);
      }}
    >
      <Span
        display="flex"
        alignItems="center"
        paddingVertical={2}
        border="bottom"
        borderWidth={2}
        borderColor={isActive ? 'blue1' : 'transparent'}
        hovered={{
          borderColor: 'blue1',
        }}
        {...TextVariantMap['body2-bold']}
      >
        {item.linkText}
      </Span>
      {open && Array.isArray(item.subMenuItems) && item.subMenuItems.length > 0 && (
        <Div
          zIndex="max"
          maxWidth={8}
          position="absolute"
          style={{
            marginTop: 1,
            minWidth: 100,
            top: '100%',
            left: subMenuPosition,
            transition: 'all 0.5s',
          }}
        >
          <Ul
            backgroundColor="white"
            marginTop={0}
            paddingHorizontal={0}
            paddingVertical={1}
            className="list o-1 shadow-5"
          >
            {(item.subMenuItems || []).map(subItem => {
              const href = getHrefFromMenuItem(subItem);

              return (
                <Li key={`ListLinkItem-li-${subItem.contentfulId}`}>
                  {href ? (
                    <LocaleLink
                      to={href}
                      variant={isAbsoluteURL(href) ? 'anchor' : 'link'}
                      display="block"
                      paddingVertical={1}
                      paddingHorizontal={2}
                      {...TextVariantMap['body2-bold']}
                      className="black subnav-item"
                    >
                      {subItem.linkText}
                    </LocaleLink>
                  ) : (
                    <Span
                      display="block"
                      paddingVertical={1}
                      paddingHorizontal={2}
                      {...TextVariantMap['body2-bold']}
                      className="black subnav-item"
                    >
                      {subItem.linkText}
                    </Span>
                  )}
                </Li>
              );
            })}
          </Ul>
        </Div>
      )}
    </Li>
  ) : (
    <Li
      display="flex"
      color={isActive ? 'blue1' : 'black'}
      alignItems="center"
      position="relative"
      marginRight={5}
      {...TextVariantMap['body2-bold']}
    >
      <Div display="flex" justifyContent="between">
        <LocaleLink
          to={href}
          variant={isDirectLink(href) ? 'anchor' : 'link'}
          display="flex"
          paddingVertical={2}
          alignItems="center"
          {...TextVariantMap['body2-bold']}
          color={rightLink ? 'br-blue' : isActive ? 'blue1' : 'black'}
          border="bottom"
          borderWidth={2}
          borderColor={isActive || hovered ? 'blue1' : 'transparent'}
          onMouseEnter={e => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
        >
          {item.linkText}
        </LocaleLink>
      </Div>
    </Li>
  );
};

const MobileNav: React.FC<SubNavBladeProps> = ({ blade, pagePrefix }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const locale = useLocale() || 'en-US';

  const activeTab = getActiveTab(
    pagePrefix,
    location.pathname,
    blade.menuItems,
    locale,
    location.hash
  );

  return (
    <Nav
      zIndex={4}
      style={{
        top: 60,
      }}
      position={blade.stickyNav ? 'sticky' : 'static'}
      backgroundColor="white"
    >
      <Div
        role="button"
        aria-expanded={isOpen ? 'true' : 'false'}
        tabIndex={0}
        display={['flex', 'none']}
        justifyContent="between"
        border="bottom"
        borderColor="gray2"
        paddingHorizontal={3}
        paddingVertical={2}
        onClick={() => setIsOpen(!isOpen)}
        onKeyPress={e => {
          if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
            setIsOpen(!isOpen);
            e.preventDefault();
          }
        }}
      >
        <Text variant="body2-bold">
          {activeTab ? activeTab.linkText : blade.menuTitle ? blade.menuTitle : ''}
        </Text>
        <Div>
          <Icon fill="black" type="caret-down" rotate={isOpen ? 0 : 270} />
        </Div>
      </Div>
      <Div
        display={isOpen ? 'block' : 'none'}
        shadow={3}
        position="absolute"
        width="100%"
        backgroundColor="white"
        zIndex={2}
      >
        <Ul paddingLeft={0} marginVertical={0} className="list">
          {blade.menuItems.map(item => (
            <MobileMenuItem key={`mobile-nav-item-1-${item.contentfulId}`} item={item} />
          ))}

          {blade.rightMenuItems?.map(item => (
            <MobileMenuItem key={`mobile-nav-item-1-${item.contentfulId}`} item={item} />
          ))}
        </Ul>
      </Div>
    </Nav>
  );
};

interface MobileMenuItemProps {
  item: ContentfulBladeSubNavMenuItem;
}

const ButtonOrLink = makeStyledComponent<{
  className: string;
  variant: 'anchor' | 'link';
  to: string | null;
  onClick: React.ButtonHTMLAttributes<'button'>['onClick'];
}>(props => {
  return props.to ? (
    <LocaleLink {...omit(props, 'onClick')} variant={props.variant} to={props.to}></LocaleLink>
  ) : (
    <Button backgroundColor="transparent" border="none" {...props}></Button>
  );
});

const MobileMenuItem: FC<MobileMenuItemProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const href = getHrefFromMenuItem(item);

  return (
    <Li marginVertical={0} marginHorizontal={0}>
      <ButtonOrLink
        to={href}
        variant={isDirectLink(href) ? 'anchor' : 'link'}
        onClick={e => setIsOpen(!isOpen)}
        display="flex"
        width="100%"
        paddingHorizontal={3}
        paddingVertical={2}
        color="black"
        textAlign="left"
        {...TextVariantMap['body2']}
      >
        <Span flex="auto">{item.linkText}</Span>
        {item.subMenuItems && (
          <Span>
            <Icon fill="black" type="caret-down" rotate={isOpen ? 0 : 270} />
          </Span>
        )}
      </ButtonOrLink>
      {isOpen && (
        <Ul className="list" paddingLeft={4}>
          {item.subMenuItems?.map(item => (
            <MobileMenuItem key={`MobileMenuItem-${item.contentfulId}`} item={item} />
          ))}
        </Ul>
      )}
    </Li>
  );
};

function getActiveTab(
  pagePrefix: string,
  pathname: string,
  tabs: ContentfulBladeSubNav['menuItems'],
  locale: string = 'en-US',
  locationhash?: string
) {
  for (let i = 0; i < tabs.length; i++) {
    if (
      isActiveBasedOnLocation(tabs[i], pathname, pagePrefix, locale) ||
      locationhash === tabs[i].url
    ) {
      return tabs[i];
    }
  }
}

type SubNavItemForActiveBasedOnLocation = Pick<ContentfulBladeSubNavMenuItem, 'url'> & {
  subMenuItems?: SubNavItemForActiveBasedOnLocation[];
  page?: Pick<Page, 'relativePath'>;
};

export function isActiveBasedOnLocation(
  item: SubNavItemForActiveBasedOnLocation,
  pathname: string,
  pagePrefix: string,
  locale: string
): boolean {
  if (Array.isArray(item.subMenuItems)) {
    for (let i = 0; i < item.subMenuItems.length; i++) {
      const subMenuItem = item.subMenuItems[i];

      if (isActiveBasedOnLocation(subMenuItem, pathname, pagePrefix, locale)) {
        return true;
      }
    }
  }

  const selectedLocale = locale.toLocaleLowerCase() === 'en-us' ? '' : locale.toLocaleLowerCase();

  if (item.page && item.page.relativePath) {
    return (
      pathJoin('/', selectedLocale, item.page.relativePath, '/') === pathJoin('/', pathname, '/')
    );
  }
  if (item.url) {
    if (isAbsoluteURL(item.url)) {
      return false;
    }
    return (
      pathJoin('/', selectedLocale, item.url, '/')?.replace('//', '/') ===
      pathJoin('/', pathname, '/')
    );
  }
  return false;
}

export function getHrefFromMenuItem(
  item: Pick<ContentfulBladeSubNavMenuItem, 'url'> & { page?: Pick<Page, 'relativePath'> }
): string | null {
  let href: string | null = null;
  const url = item.url;

  if (item.page && item.page.relativePath) {
    href = pathJoin(item.page.relativePath || '');
  } else if (url) {
    href = isDirectLink(url) ? url : pathJoin('/', url || '');
  }

  return href;
}
